
import { metaDataMixin, dateFormatMixin } from '~/mixins'

const PLAYLIST_ID = '8da67b26-399a-4287-a8ea-05a0b62ef876'

export default {
  mixins: [
    dateFormatMixin,
    metaDataMixin,
  ],
  async asyncData ({ $api, $config }) {
    const [
      { data: { items: featuredItems = [] } = {} },
      { data: { items: popularItems = [] } = {} },
      { data: { items: recommendedItems = [] } = {} },
      { data: { items: calltoactionItems = [] } = {} },
      { data: { item: playlistItem } = {} },
      { data: { items: latestEpisodes = [] } = {} },
    ] = await Promise.all([
      // Get featured articles (Uitgelicht)
      $api.articles.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomain,
        limit: 1,
      }),
      // Get popular articles (Populair)
      $api.articles.getAll({
        channel: 'popular',
        domain: $config.preprEnvironmentDomain,
        limit: 3,
      }),
      // Get recommended articles (Aanbevolen)
      $api.articles.getAll({
        channel: 'recommended',
        domain: $config.preprEnvironmentDomain,
        limit: 3,
      }),
      // Get call to actions
      $api.calltoactions.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomain,
        limit: 4,
      }),
      // Get video playlist
      $api.playlists.getOne(PLAYLIST_ID),
      // Get latest broadcasted episode
      $api.poms.playables.getAll({
        descendantOf: 'VPWON_1257874',
        limit: 1,
      }),
    ])

    return {
      articles: [...featuredItems], // clone items
      articlesFeatured: featuredItems,
      articlesPopular: popularItems,
      articlesRecommended: recommendedItems,
      calltoactions: calltoactionItems,
      latestEpisode: latestEpisodes[0],
      playlist: playlistItem && playlistItem,
    }
  },
  data () {
    return {
      activeTabsList: 0,
      articles: [],
      articlesFeatured: [],
      articlesPopular: [],
      articlesRecommended: [],
      hasMore: true,
      hero: 'https://blauwbloed-eo.cdn.eo.nl/{format}/s3-blauwbloed-eo/152bf761-031b-4477-8d62-1f6ac767967d.png',
      latestEpisode: {},
      playlist: null,
      calltoactions: [],
    }
  },
  async fetch () {
    const showAtLeast = 13
    const limit = this.articles.length < showAtLeast ? showAtLeast - this.articles.length : 9
    const offset = this.articles.length - this.articlesFeatured.length
    const { data: { items: articles = [], meta: { total = 0 } } } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomain,
      offset,
      limit,
    })

    this.articles.push(...articles)
    this.hasMore = total > this.articles.length
  },
  computed: {
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
  },
}
